import { createSlice } from "@reduxjs/toolkit";
import {
  getAllAppointments,
  getAvailableSlots,
  verifyBooking,
} from "./thunkAction";

export interface IAppointment {
  id: string;
  date_created: string;
  date_updated: string;
  tenant_id: string;
  title: string;
  service_items: {
    name: string;
    price: string;
    quantity: number;
    service_id: string;
  }[];
  available_time_from: string;
  available_time_to: string;
  days_of_week: string[];
  duration_per_session: number;
  duration_per_session_unit: "minutes";
  no_clients: number;
  booking_fee: string;
  booking_fee_currency: string;
  note_for_customers: string;
  status: "active";
  tenant_info: {
    name: string;
    contact: {
      email: string;
      last_name: string;
      first_name: string;
      country_code: string;
      phone_number: string;
    };
  };
}

export interface IBookingSlots {
  month: number;
  year: number;
  slots: string[];
}

export interface IBookingDetails {
  id: number;
  domain: string;
  status: "success";
  reference: string;
  receipt_number: string;
  amount: number;
  message: string;
  gateway_response: string;
  paid_at: string;
  created_at: string;
  channel: string;
  currency: string;
  ip_address: string;
  metadata: string;
  // log: {
  //   start_time: 1732107524;
  //   time_spent: 11;
  //   attempts: 1;
  //   errors: 0;
  //   success: true;
  //   mobile: false;
  //   input: [];
  //   history: {
  //     type: "action" | "success";
  //     message: "Attempted to pay with card";
  //     time: 9;
  //   }[];
  // };
  fees: number;
  fees_split: any;
  authorization: {
    authorization_code: string;
    bin: string;
    last4: string;
    exp_month: string;
    exp_year: string;
    channel: string;
    card_type: string;
    bank: string;
    country_code: string;
    brand: string;
    reusable: boolean;
    signature: string;
    account_name: string;
  };
  customer: {
    id: number;
    first_name: string
    last_name: string;
    email: string;
    customer_code: string;
    phone: string;
    metadata: string;
    risk_action: string;
    international_format_phone: string;
  };
  plan: null;
  split: {};
  order_id: null;
  paidAt: string;
  createdAt: string;
  requested_amount: number;
  pos_transaction_data: null;
  source: null;
  fees_breakdown: null;
  connect: null;
  transaction_date: string;
  plan_object: {};
  subaccount: {};
}

interface IState {
  loading: "failed" | "loading" | "successful" | "idle";
  actionLoading: "failed" | "loading" | "successful" | "idle";
  slotsLoading: "failed" | "loading" | "successful" | "idle";
  appointments: IAppointment[];
  bookingSlots: IBookingSlots;
  bookingDetails: IBookingDetails;
}
const initialState: IState = {
  loading: "idle",
  actionLoading: "idle",
  slotsLoading: "idle",
  appointments: [] as IAppointment[],
  bookingSlots: {} as IBookingSlots,
  bookingDetails: {} as IBookingDetails,
};
const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllAppointments.pending, (state) => {
      return { ...state, loading: "loading" };
    });
    builder.addCase(getAllAppointments.fulfilled, (state, action) => {
      return {
        ...state,
        loading: "successful",
        appointments: action.payload,
      };
    });
    builder.addCase(getAllAppointments.rejected, (state) => {
      return { ...state, loading: "failed" };
    });
    builder.addCase(getAvailableSlots.pending, (state) => {
      return { ...state, slotsLoading: "loading" };
    });
    builder.addCase(getAvailableSlots.fulfilled, (state, action) => {
      return {
        ...state,
        slotsLoading: "successful",
        bookingSlots: action.payload,
      };
    });
    builder.addCase(getAvailableSlots.rejected, (state) => {
      return { ...state, slotsLoading: "failed" };
    });
    builder.addCase(verifyBooking.pending, (state) => {
      return { ...state, actionLoading: "loading" };
    });
    builder.addCase(verifyBooking.fulfilled, (state, action) => {
      return {
        ...state,
        actionLoading: "successful",
        bookingDetails: action.payload,
      };
    });
    builder.addCase(verifyBooking.rejected, (state) => {
      return { ...state, actionLoading: "failed" };
    });
  },
});

export const BookingReducer = BookingSlice.reducer;
