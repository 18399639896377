import { useState } from "react";
import { useFormik } from "formik";
import { useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { useAppThunkDispatch, useAppSelector } from "src/store/store";
import { Input } from "src/component/ui/input";
import CustomerAdded from "./modals/success";
import { registerCustomer } from "src/store/customer/thunkAction";
import { Avatar, AvatarFallback, AvatarImage } from "src/component/ui/avatar";
import { toast } from "react-toastify";
import { Building2 } from "lucide-react";

export const CustomerRegisterSchema = yup.object().shape({
  fullname: yup.string().required("Enter name"),
  email: yup.string().email("Invalid email format"),
  phone_number: yup
    .string()
    .required()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Must be a valid phone number"
    )
    .min(11, "Must be exactly 11 digits")
    .max(11, "Must be exactly 11 digits"),
});

const CustomerRegister = () => {
  const { id } = useParams();
  const params = useSearchParams();
  const merchantName = params[0].get("merchant-name") || "";
  const logo =
    params[0].get("logo") === "None" ? "" : params[0].get("logo") || "";

  const dispatch = useAppThunkDispatch();
  const { loading: actionLoading } = useAppSelector(({ customer }) => customer);

  const [modalOpen, setModalOpen] = useState(false);
  const onClose = () => {
    setModalOpen(false);
  };

  const onSubmit = async () => {
    if (!id) {
      toast.error("An error occurred");
      return;
    }

    if (!values.email) {
      delete values["email"];
    }

    dispatch(registerCustomer({ id: id, body: values })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setModalOpen(true);
        resetForm();
      }
    });
  };

  const {
    values,
    handleChange,
    handleSubmit,
    // handleBlur,
    // isSubmitting,
    errors,
    resetForm,
    // touched,
  } = useFormik({
    initialValues: {
      fullname: "",
      phone_number: "",
      email: "",
    } as { fullname: string; phone_number: string; email?: string },
    validationSchema: CustomerRegisterSchema,
    onSubmit: onSubmit,
  });

  if (!id)
    return (
      <div className="bg-[#F8FAFC] grid place-items-center rounded-2xl shadow-[2px_2px_8px_0px_#9494940D] h-[100vh]">
        Error: Invalid merchant url. Please ensure you have the right link
      </div>
    );

  return (
    <>
      <div className="bg-[#F8FAFC] grid place-items-center rounded-2xl shadow-[2px_2px_8px_0px_#9494940D] min-h-[100vh] hide-on-print">
        <div className="bg-white flex flex-col lg:max-w-[653px] lg:w-[50vw] lg:h-[75vh] lg:max-h-[75vh] h-screen max-h-screen w-full lg:my-10">
          <div className="h-[80px] py-2 lg:px-4 shadow-[0px_1px_2px_0px_#2563EB1A] lg:text-3xl font-medium flex justify-center items-center gap-4">
            <Avatar className="w-[2.4rem] h-[2.4rem] border border-[#E2E8F0] text-base">
              <AvatarImage src={logo || ""} alt="logo" />
              <AvatarFallback>
                <Building2 />
              </AvatarFallback>
            </Avatar>
            <p>{merchantName || ""}</p>
          </div>
          <div className="bg-[#EFF6FF] py-2 text-[#3B82F6] font-medium border-y border-y-[#F1F5F9] px-2">
            <p>
              Thank you for taking the time to provide your information. Please
              fill out the form below with your details.{" "}
            </p>
          </div>
          <div className="flex flex-col justify-between h-full">
            <form onSubmit={handleSubmit}>
              <div className="p-4 grid gap-4 py-8">
                <Input
                  labelText="Full name"
                  placeholder="Full name"
                  value={values.fullname}
                  error={errors?.fullname || ""}
                  type="text"
                  id={"fullname"}
                  name={"fullname"}
                  onChange={handleChange}
                />
                <Input
                  labelText="Phone number"
                  placeholder="Phone number"
                  value={values.phone_number}
                  error={errors?.phone_number || ""}
                  type="text"
                  id={"phone_number"}
                  name={"phone_number"}
                  onChange={handleChange}
                />
                <Input
                  labelText="Email"
                  placeholder="Email address (optional)"
                  value={values.email}
                  error={errors?.email || ""}
                  type="text"
                  id={"email"}
                  name={"email"}
                  onChange={handleChange}
                />
              </div>
              <div className="border-t border-t-[#F1F5F9] h-20 shadow-[0px_-4px_6px_0px_#2563EB0D] py-3 px-4">
                <button
                  className={`flex items-center bg-[#2563EB] w-full mx-auto lg:w-[80%] text-[#FFFFFF] h-full rounded font-[700] cursor-pointer justify-center border-none outline-none ${
                    actionLoading === "loading" ? "opacity-60" : ""
                  }`}
                  disabled={actionLoading === "loading"}
                  type="submit"
                >
                  Submit details
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CustomerAdded open={modalOpen} onClose={onClose} />
    </>
  );
};

export default CustomerRegister;
