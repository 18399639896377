import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { Drawer } from "rsuite";
import * as yup from "yup";
import Services from "../fragments/services";
import Details from "../fragments/details";
import CustomerInfo from "../fragments/customerInfo";
import { useParams } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { bookAppointment } from "src/store/booking/thunkAction";
import { useAppThunkDispatch } from "src/store/store";

export const BookingSchema = yup.object().shape({
  tenant_id: yup.string().required(""),
  fee: yup.number(),
  currency: yup.string(),
  serviceName: yup.string().required("Enter service name"),
  service_id: yup.string().required("Enter service name"),
  appointment_id: yup.string().required("Enter service name"),
  appointment_time_from: yup.string().required("Enter appointment time"),
  appointment_time_to: yup.string().required("Enter appointment time"),
  appointment_date: yup.string().required("Enter appointment date"),
  time: yup.string().required("Enter appointment timer"),
  fullname: yup.string().required("Enter full name"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  phone_number: yup
    .string()
    .required()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Must be a valid phone number"
    )
    .min(11, "Must be exactly 11 digits")
    .max(11, "Must be exactly 11 digits"),
  note_to_vendor: yup.string(),
});

const AppointmentModal = ({ open, onClose, data }) => {
  const onSubmit = async (values, actions) => {
    const { username, password } = values;

    const data = {
      username,
      password,
    };
    console.log(data);
  };

  const {
    values,
    handleChange,
    // handleSubmit,
    handleBlur,
    // isSubmitting,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      tenant_id: "",
      appointment_id: "",
      service_id: "",
      serviceName: "",
      appointment_date: "",
      appointment_time_from: "",
      appointment_time_to: "",
      time: "",
      fee: 0,
      currency: "",
      fullname: "",
      phone_number: "",
      email: "",
      note_to_vendor: "",
    },
    validationSchema: BookingSchema,
    onSubmit,
  });

  const { id } = useParams();
  useEffect(() => {
    setFieldValue("appointment_id", data.id);
    setFieldValue("currency", data.booking_fee_currency);
    setFieldValue("fee", data.booking_fee);
    setFieldValue("tenant_id", id);
  }, [setFieldValue, data.id, id, data.booking_fee, data.booking_fee_currency]);
  const dispatch = useAppThunkDispatch();
  const [step, setStep] = useState(1);
  const next = () => {
    if (step === 1) {
      setStep(2);
    }
    if (step === 2) {
      if (!!errors.appointment_date) {
        toast.error("kindly select date");
      }
      if (!!errors.appointment_time_from || !!errors.appointment_time_to) {
        toast.error("Kindly select time");
      }
      if (
        !errors.appointment_time_from &&
        !errors.appointment_time_to &&
        !errors.appointment_date
      ) {
        setStep(3);
      }
    }
    if (step === 3) {
      if (!!errors.fullname || !!errors.email || !!errors.phone_number) {
        toast.error("kindly fill all required fields");
      } else {
        dispatch(bookAppointment(values)).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            const response = res.payload;
            localStorage.setItem("paymentInfo", JSON.stringify(response));
            window.location.href =
              response.initiate_transaction_response.authorization_url;
            onClose();
          }
        });
      }
    }
  };
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [size, setSize] = React.useState("full");
  useEffect(() => {
    if (isDesktop) {
      setSize("sm");
    } else {
      setSize("full");
    }
  }, [isDesktop]);

  return (
    <Drawer
      placement={"right"}
      open={open}
      onClose={onClose}
      size={size}
      className="w-full"
    >
      <Drawer.Header
        style={{ paddingInline: "16px", alignItems: "center", gap: "8px" }}
        closeButton={false}
      >
        <div
          onClick={() => {
            step === 1 ? onClose() : setStep((step) => step - 1);
          }}
          className="flex items-center cursor-pointer"
        >
          {/* <img src="/signup-icons/arrowLeft.svg" alt="" /> */}
          <ArrowLeft />
        </div>
        <Drawer.Title>
          <p className="font-semibold text-[#475569] text-xl">Book Now</p>
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body
        style={{
          paddingInline: "0",
          paddingTop: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {step === 1 && (
          <Services
            setStep={setStep}
            data={data}
            setFieldValue={setFieldValue}
          />
        )}
        {step === 2 && (
          <Details
            values={values}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        )}
        {step === 3 && (
          <CustomerInfo
            values={values}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            data={data}
          />
        )}
        {step !== 1 && (
          <div className="mt-auto h-4 gap-8 px-4 py-3 border-t border-t-[#F1F5F9] shadow-[0px_-4px_6px_0px_#2563EB0D] flex justify-between">
            <div
              className={`flex items-center bg-[#F1F5F9] w-full text-[#475569] h-10 rounded-xl font-[700] cursor-pointer justify-center`}
              onClick={onClose}
            >
              Cancel
            </div>
            <div
              className="flex items-center bg-[#2563EB] w-full text-[#FFFFFF] h-10 rounded-xl font-[700] cursor-pointer justify-center"
              onClick={next}
            >
              Proceed
            </div>
          </div>
        )}
      </Drawer.Body>
    </Drawer>
  );
};

export default AppointmentModal;
