import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";

export const useAxios = async (
  request: AxiosRequestConfig
): Promise<AxiosApiResponse> => {
  try {
    const response: AxiosApiResponse = await axios.request({
      ...request,
      headers: {
        ...request.headers,
        authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      // withCredentials: true,
    });

    if (response.status_code === 401) {
      localStorage.clear();
    }
    return response;
  } catch (error: any) {
    if (error.code === "EONNABORTED") {
      toast.error("Request timed out");
    } else {
      throw error
    }
    const axiosError = error as AxiosError;
    const errorMessage =
      (axiosError.response?.data as { message?: string })?.message ||
      "An error occurred";
    return {
      data: null,
      error: errorMessage,
      status_code: Number(axiosError.response?.status),
    };
    // throw error
  }
};

export interface AxiosApiResponse<T = any> {
  data: T | null;
  error: string | null;
  status_code: number;
}
