import React from "react";
import { Modal } from "rsuite";
import styled from "styled-components";
import successfull from "./../../asset/svgs/success-logo.svg";
// import toast from "react-hot-toast";

const CustomModal = styled(Modal)`
  width: 343px !important;
  .rs-modal-content {
    padding: 0px;
  }
`;
export default function CustomerAdded({ open, onClose }) {
  return (
    <CustomModal open={open} onClose={onClose}>
      <div className="flex flex-col items-center bg-whitew-[343px] h-[270px] p-6 ">
        
        <div className="flex flex-col items-center text-center gap-1">
          <img src={successfull} alt="successfull" />
          <p className="text-txgray4 text-[22px] font-semibold">
            Details submitted succesfully
          </p>
          <p>Thank you for providing your details</p>
        </div>
      </div>

    </CustomModal>
  );
}
