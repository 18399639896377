import React from "react";


const CustomerInformation = ({
  touched,
  values,
  errors,
  handleChange,
  handleBlur,
  data,
}) => {
  return (
    <div className="flex flex-col justify-between">
      <div>
        <div className="text-center bg-[#EFF6FF] py-2 text-[#64748B] font-medium border-y border-y-[#F1F5F9]">
          <p>Client Information</p>
        </div>
        <div className="p-4 grid gap-4">
          <div
            className={`input-box ${
              !errors.fullname && touched.fullname && values.fullname
                ? "filled"
                : errors.fullname && touched.fullname
                ? "error"
                : ""
            }`}
          >
            <input
              type="text"
              autoComplete="off"
              id="fullname"
              name="fullname"
              value={values.fullname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="fullname" className="label-name">
              <span className="label-text">Full name</span>
            </label>
          </div>
          <div
            className={`input-box ${
              !errors.phone_number && touched.phone_number && values.phone_number
                ? "filled"
                : errors.phone_number && touched.phone_number
                ? "error"
                : ""
            }`}
          >
            <input
              type="text"
              autoComplete="off"
              id="phone_number"
              name="phone_number"
              value={values.phone_number}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="phone_number" className="label-name">
              <span className="label-text">Phone number</span>
            </label>
          </div>
          <div
            className={`input-box ${
              !errors.email && touched.email && values.email
                ? "filled"
                : errors.email && touched.email
                ? "error"
                : ""
            }`}
          >
            <input
              type="text"
              autoComplete="off"
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="email" className="label-name">
              <span className="label-text">Email</span>
            </label>
          </div>
          <div
            className={`input-box ${
              !errors.note_to_vendor && touched.note_to_vendor && values.note_to_vendor
                ? "filled"
                : errors.note_to_vendor && touched.note_to_vendor
                ? "error"
                : ""
            }`}
          >
            <input
              type="text"
              autoComplete="off"
              id="note_to_vendor"
              name="note_to_vendor"
              value={values.note_to_vendor}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="note_to_vendor" className="label-name">
              <span className="label-text">Note</span>
            </label>
          </div>
          <div className=" bg-[#EFF6FF] py-2 text-[#64748B] font-medium text-xs px-4 border-y border-y-[#F1F5F9]">
          <p>Booking this service will require you to pay a booking fee of N{data?.booking_fee}</p>
        </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInformation;
