import { useState, useEffect } from "react";
import AppointmentModal from "./modals/appointmentModal";
import VerificationModal from "./modals/verificationModal";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppThunkDispatch, useAppSelector } from "src/store/store";
import { getAllAppointments } from "src/store/booking/thunkAction";
import Loader from "../component/atoms/Loader";
import { IAppointment } from "src/store/booking/bookingSlice";
import { useTimeFormatter } from "src/hooks/useTimeFormatter";

const Booking = () => {
  const { id } = useParams();
  const searchParams = useSearchParams();
  const reference = searchParams[0].get("reference") || "";
  const [view, setView] = useState(false);
  const dispatch = useAppThunkDispatch();
  const { loading, appointments } = useAppSelector(({ booking }) => booking);
  useEffect(() => {
    if (id) {
      dispatch(getAllAppointments(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    setView(!!reference);
  }, [reference]);

  if (!id)
    return (
      <div className="bg-[#F8FAFC] grid place-items-center rounded-2xl shadow-[2px_2px_8px_0px_#9494940D] h-[100vh]">
        Error: Invalid booking url
      </div>
    );

  return (
    <>
      {loading === "loading" ? (
        <div className="bg-[#F8FAFC] grid place-items-center rounded-2xl shadow-[2px_2px_8px_0px_#9494940D] h-[100vh]">
          <Loader />
        </div>
      ) : (
        <div className="bg-[#F8FAFC] grid place-items-center rounded-2xl shadow-[2px_2px_8px_0px_#9494940D] min-h-[100vh] hide-on-print">
          <div className="bg-white lg:max-w-[1000px] lg:w-[75vw] lg:min-h-[80vh] min-h-[100vh] w-full lg:my-10">
            <div className="h-[80px] py-2 shadow-[0px_1px_2px_0px_#2563EB1A] text-3xl font-medium flex justify-center items-center gap-4">
              {appointments?.[0]?.tenant_info?.name ||
                "No appointments available"}
            </div>
            <div className="text-center bg-[#EFF6FF] py-2 text-[#64748B] font-medium border-y border-y-[#F1F5F9]">
              <p>Available services for booking</p>
            </div>
            {loading === "failed" ? (
              <div className="bg-[#F8FAFC] grid place-items-center rounded-2xl shadow-[2px_2px_8px_0px_#9494940D] h-[100vh]">
                Error fetching data
              </div>
            ) : (
              <div className="">
                {appointments.map((appointment, idx) => {
                  return <Appointment key={idx} appointment={appointment} />;
                })}
              </div>
            )}
          </div>
        </div>
      )}
      <VerificationModal
        open={view}
        onClose={() => {
          setView(false);
          window.location.href = `/booking/${id}`;
        }}
      />
    </>
  );
};

export default Booking;

const Appointment = ({ appointment }: { appointment: IAppointment }) => {
  const [view, setView] = useState(false);
  return (
    <div className="px-8 mt-4">
      <div className="flex justify-between">
        <p className="font-bold text-[#334155] tracking-wider text-base">
          {appointment?.title}
        </p>
        <p className="text-xs font-semibold text-[#3B82F6] mt-0">
          {useTimeFormatter(appointment?.available_time_from)} -{" "}
          {useTimeFormatter(appointment?.available_time_to)}
        </p>
      </div>
      <div className="border-l border-l-[#BBB] flex my-2 justify-evenly w-full border-y border-y-[#BBB]">
        {appointment.days_of_week.map((day, idx) => {
          return (
            <div
              key={idx}
              className="text-center border-r border-r-[#BBB] w-full py-1"
            >
              <p className="text-[#64748B] text-xs tracking-wider">{day}</p>
            </div>
          );
        })}
      </div>
      <div className="flex justify-between text-sm font-medium items-center">
        <p
          className={`${
            appointment?.no_clients > 1 ? "text-green-500" : "text-red-500"
          } `}
        >
          Slots available: {appointment?.no_clients}
        </p>
        <p className="text-[#64748B] mt-0">
          Booking fee:{appointment?.booking_fee_currency}{" "}
          {Intl.NumberFormat("en-us", { minimumFractionDigits: 2 }).format(
            Number(appointment?.booking_fee)
          )}
        </p>
      </div>
      <div className="flex justify-end my-3">
        <div
          className="flex items-center bg-[#2563EB] px-5 text-[#FFFFFF] md:py-2 py-1 rounded-3xl font-[700] cursor-pointer"
          onClick={() => setView(true)}
        >
          View services
        </div>
      </div>
      <div className="border-y border-y-[#F1F5F9]"></div>
      <AppointmentModal
        open={view}
        onClose={() => setView(false)}
        data={appointment}
      />
    </div>
  );
};
