import { combineReducers } from "redux";
import userSlice from "./slices/userSlice";
import payrollSlice from "./slices/PayrollSlice";
import { BookingReducer } from "./booking/bookingSlice";
import { CustomerReducer } from "./customer/bookingSlice";

const rootReducer = combineReducers({
  users: userSlice,
  payroll: payrollSlice,
  booking: BookingReducer,
  customer: CustomerReducer,
});

export default rootReducer;
