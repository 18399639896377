import { useAxios } from "src/hooks/useAxios";
import { useApiErrorHandler } from "src/hooks/useErrorHandler";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API;
interface ICustomerRegister {
  fullname: string;
  phone_number: string;
  email?: string;
}

export const registerCustomer = createAsyncThunk(
  "register-customer",
  async (data: { id: string; body: ICustomerRegister }, thunkAPI) => {
    const REGISTER_CUSTOMER = toast.loading("Registering, please wait...");
    try {
      const response = await useAxios({
        url: `${BASE_URL}/m/customers/qrcode/${data.id}/register-customer`,
        method: "POST",
        data: data.body,
      });

      toast.update(REGISTER_CUSTOMER, {
        render: response.data.message,
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data as {
          status_code: number;
          message: string;
        };
        useApiErrorHandler(err, REGISTER_CUSTOMER);
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue(String(error));
      }
    }
  }
);
