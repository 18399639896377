import { Id, toast } from "react-toastify";

export const useApiErrorHandler = (
  err: {
    status_code: number;
    message: string;
  },
  toastId?: Id
) => {
  if (err.status_code === 0) {
    toast.error("network error");
  }
  if (err.status_code === 401) {
    toast.error(`${err?.message}`);
    localStorage.removeItem("userToken");
    localStorage.removeItem("user");
    setTimeout(() => {
      window.location.replace(`/auth/login`);
    }, 1000);
  }
  toastId
    ? toast.update(toastId, {
        render: err.message.includes(`not found`)
          ? "Network error-an error occurred"
          : err.message,
        type: "error",
        isLoading: false,
        autoClose: 2000,
        toastId: "error",
      })
    : toast.error(err.message);
};
