import React, { useState, useEffect } from "react";
import { Drawer } from "rsuite";
// import * as yup from "yup";
import { useParams, useSearchParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
// import { ArrowLeft } from "lucide-react";
import { useMediaQuery } from "@mui/material";
// import { toast } from "react-toastify";
import { verifyBooking } from "src/store/booking/thunkAction";
import { useAppThunkDispatch, useAppSelector } from "src/store/store";
import Loader from "src/component/atoms/Loader";
import dayjs from "dayjs";
// import { toast } from "react-toastify";

interface PaymentInfo {
  initiate_transaction_response: {
    authorization_url: string;
    access_code: string;
    reference: string;
  };
  customer_appointment_id: string;
}
const VerificationModal = ({ open, onClose }: any) => {
  const { id } = useParams();
  const searchParams = useSearchParams();
  const reference = searchParams[0].get("reference") || "";
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>(
    {} as PaymentInfo
  );
  const [verified, setVerified] = useState(false);
  useEffect(() => {
    setPaymentInfo(JSON.parse(localStorage.getItem("paymentInfo") || "{}"));
  }, []);
  const dispatch = useAppThunkDispatch();
  const { actionLoading, bookingDetails, appointments } = useAppSelector(
    ({ booking }) => booking
  );
  useEffect(() => {
    if (id && paymentInfo.customer_appointment_id && reference) {
      dispatch(
        verifyBooking({
          tenant_id: id || "",
          reference,
          customer_appointment_id: paymentInfo.customer_appointment_id,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setVerified(true);
        }
      });
    }
  }, [dispatch, paymentInfo.customer_appointment_id, id, reference]);

  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [size, setSize] = React.useState("full");
  useEffect(() => {
    if (isDesktop) {
      setSize("sm");
    } else {
      setSize("full");
    }
  }, [isDesktop]);

  const handlePrint = () => {
    const element = document.getElementById("printSection"); // Target the section
    const options = {
      margin: 1,
      filename: "transaction-receipt.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Generate the PDF
    html2pdf().set(options).from(element).save();
  };

  // const handleShareWithPDF = () => {
  //   const element = document.getElementById('printSection');
  //   const options = {
  //     margin: 1,
  //     filename: 'transaction-receipt.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  //   };
  
  //   // Generate the PDF as a Blob
  //   html2pdf().set(options).from(element).outputPdf('blob').then((pdfBlob:any) => {
  //     if (navigator.canShare && navigator.canShare({ files: [pdfBlob] })) {
  //       const file = new File([pdfBlob], 'transaction-receipt.pdf', { type: 'application/pdf' });
  //       const shareData = {
  //         files: [file],
  //         title: 'Transaction Receipt',
  //         text: 'Here is your transaction receipt.'
  //       };
  
  //       navigator.share(shareData)
  //         .then(() => console.log('Shared successfully!'))
  //         .catch((error) => console.error('Error sharing:', error));
  //     } else {
  //       toast.warn('Sharing files is not supported on this device.');
  //     }
  //   });
  // };

  return (
    <Drawer
      placement={"right"}
      open={open}
      onClose={onClose}
      size={size}
      className="w-full"
    >
      <Drawer.Header
        style={{ paddingInline: "16px", alignItems: "center", gap: "8px" }}
        closeButton={false}
      >
        {/* <div className="flex items-center cursor-pointer"> */}
        {/* <ArrowLeft /> */}
        {/* </div> */}
        <Drawer.Title>
          <p className="font-semibold text-[#475569] text-xl">
            {verified ? "Transaction receipt" : "Verify Booking"}
          </p>
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body
        style={{
          paddingInline: "0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {actionLoading === "loading" ? (
          <div className="bg-[#F8FAFC] grid place-items-center rounded-2xl shadow-[2px_2px_8px_0px_#9494940D] h-[100vh]">
            <Loader />
          </div>
        ) : (
          <>
            {verified ? (
              <>
                <div
                  className="bg-[#F8FAFC] h-[calc(100vh-20px)] grid place-items-center"
                  id="printSection"
                >
                  <div className="bg-white w-[80%] h-[90%] rounded-2xl p-4 px-8">
                    <div className="flex justify-end items-center w-full">
                      <img src="/signup-icons/paystack.svg" alt="" />
                    </div>
                    <div className="flex flex-col gap-2 my-8">
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-[800] text-[#475569]">
                          Transaction Receipt
                        </p>
                        <p className="text-[#467ABA] font-bold mt-0">
                          {Intl.NumberFormat("en-us", {
                            style: "currency",
                            currency: bookingDetails.currency || "NGN",
                            minimumFractionDigits: 2,
                            currencyDisplay: "narrowSymbol",
                          }).format((bookingDetails.amount || 0) / 100)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center ">
                        <p className="text-[9px] text-[#64748B]">
                          {dayjs(bookingDetails.transaction_date).format(
                            "ddd DD, YYYY::hh:mm:ss A"
                          )}
                        </p>
                        <p className="font-medium text-[#10B981] text-[9px] mt-0">
                          Successful transaction
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between py-2 border-b border-b-[#E2E8F0]">
                        <p className="text-[10px] font-[800] text-[#3D6BA3]">
                          Transaction type
                        </p>
                        <p className="mt-0 text-[9px] text-[#475569] font-medium capitalize">
                          {bookingDetails.channel}
                        </p>
                      </div>
                      <div className="flex justify-between py-2 border-b border-b-[#E2E8F0]">
                        <p className="text-[10px] font-[800] text-[#3D6BA3]">
                          Payer
                        </p>
                        <div className="text-[9px] text-[#475569] font-medium text-right">
                          <p>
                            {bookingDetails.customer?.first_name}{" "}
                            {bookingDetails?.customer?.last_name}
                          </p>
                          <p className="mt-0">
                            {bookingDetails?.customer.email}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between py-2 border-b border-b-[#E2E8F0]">
                        <p className="text-[10px] font-[800] text-[#3D6BA3]">
                          Receiver
                        </p>
                        <div className="text-[9px] text-[#475569] font-medium text-right">
                          <p>{appointments?.[0]?.tenant_info?.name}</p>
                          <p className="mt-0">
                            {appointments?.[0]?.tenant_info?.contact?.email}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between py-2 border-b border-b-[#E2E8F0]">
                        <p className="text-[10px] font-[800] text-[#3D6BA3]">
                          Transaction ID
                        </p>
                        <p className="mt-0 text-[9px] text-[#475569] font-medium">
                          {bookingDetails?.reference}
                        </p>
                      </div>
                      <div className="flex justify-between py-2 border-b border-b-[#E2E8F0]">
                        <p className="text-[10px] font-[800] text-[#3D6BA3]">
                          Session ID
                        </p>
                        <p className="mt-0 text-[9px] text-[#475569] font-medium">
                          {bookingDetails?.reference}
                        </p>
                      </div>
                      <div className="flex justify-between py-2 border-b border-b-[#E2E8F0]">
                        <p className="text-[10px] font-[800] text-[#3D6BA3]">
                          Note
                        </p>
                        <p className="mt-0 text-[9px] text-[#475569] font-medium"></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-auto h-5 gap-8 px-4 py-3 border-t border-t-[#F1F5F9] text-sm font-bold shadow-[0px_-4px_6px_0px_#2563EB0D] flex justify-between hide-on-print">
                  <div
                    className={`flex items-center bg-[#F1F5F9] w-full text-[#475569] h-10 rounded-xl font-[700] cursor-pointer justify-center`}
                    onClick={onClose}
                  >
                    Cancel
                  </div>
                  <div
                    className={`flex items-center bg-[#2563EB] w-full text-[#FFFFFF] h-10 rounded-xl font-[700] cursor-pointer justify-center`}
                    onClick={handlePrint}
                  >
                    Save
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="text-center h-[60%] flex align-center text-red-400">
                  <p>There is an issue verifying this transaction</p>
                </div>
                <div className="mt-auto h-4 gap-8 px-4 py-3 border-t border-t-[#F1F5F9] shadow-[0px_-4px_6px_0px_#2563EB0D] flex justify-between">
                  <div
                    className={`flex items-center bg-[#2563EB] w-full text-[#FFFFFF] h-10 rounded-xl font-[700] cursor-pointer justify-center`}
                    onClick={onClose}
                  >
                    Close
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Drawer.Body>
    </Drawer>
  );
};

export default VerificationModal;
