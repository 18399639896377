import { useAxios } from "src/hooks/useAxios";
import { useApiErrorHandler } from "src/hooks/useErrorHandler";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API;
interface IBookAppointment {
  tenant_id: string;
  appointment_id: string;
  service_id: string;
  service_Name: string;
  appointment_date: string;
  appointment_time_from: string;
  time: string;
  appointment_time_to: string;
  fee: number;
  currency: string;
  fullname: string;
  phone_number: string;
  email: string;
  note_to_vendor: string;
}

interface IVerifyBooking {
  tenant_id: string;
  reference: string;
  customer_appointment_id: string;
}

interface IGetSlots {
  tenant_id: string;
  appointment_id: string;
  appointment_date: string;
}

export const bookAppointment = createAsyncThunk(
  "book-appointment",
  async (data: IBookAppointment, thunkAPI) => {
    const BOOK_APPOINTMENT = toast.loading(
      "Booking appointment, please wait..."
    );
    try {
      const response = await useAxios({
        url: `${BASE_URL}/m/appointments/book`,
        method: "POST",
        data,
      });

      toast.update(BOOK_APPOINTMENT, {
        render: "Redirecting to payment..",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data as {
          status_code: number;
          message: string;
        };
        useApiErrorHandler(err, BOOK_APPOINTMENT);
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue(String(error));
      }
    }
  }
);

export const getAllAppointments = createAsyncThunk(
  "get-appointments",
  async (data: string, thunkAPI) => {
    // const FETCH_AGENTS = toast.loading("Fetching agents, please wait...");
    try {
      const response = await useAxios({
        url: `${BASE_URL}/m/appointments/${data}/list?status=active`,
        method: "GET",
      });

      // toast.update(FETCH_AGENTS, {
      //   render: response.data.message,
      //   type: "success",
      //   isLoading: false,
      //   autoClose: 2000,
      // });
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data as {
          status_code: number;
          message: string;
        };
        useApiErrorHandler(err);
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue(String(error));
      }
    }
  }
);

export const getAvailableSlots = createAsyncThunk(
  "get-available-slots",
  async (data: IGetSlots, thunkAPI) => {
    try {
      const response = await useAxios({
        url: `${BASE_URL}/m/appointments/slots-available`,
        method: "POST",
        data,
      });

      
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data as {
          status_code: number;
          message: string;
        };
        useApiErrorHandler(err);
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue(String(error));
      }
    }
  }
);

export const verifyBooking = createAsyncThunk(
  "verify-booking",
  async (data: IVerifyBooking, thunkAPI) => {
    // const BOOK_APPOINTMENT = toast.loading(
    //   "Booking appointment, please wait..."
    // );
    try {
      const response = await useAxios({
        url: `${BASE_URL}/m/appointments/verify-booking-transaction`,
        method: "POST",
        data,
      });

      // toast.update(BOOK_APPOINTMENT, {
      //   render: "Redirecting to payment..",
      //   type: "success",
      //   isLoading: false,
      //   autoClose: 2000,
      // });
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data as {
          status_code: number;
          message: string;
        };
        useApiErrorHandler(err);
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue(String(error));
      }
    }
  }
)
