import React from "react";

import { cn } from "../../lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText: string;
  error?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, labelText, error, ...props }, ref) => {
    const [isFocused, setIsFocused] = React.useState(false);

    return (
      <div className="relative">
        <label
          htmlFor={props.name}
          className={cn(
            "absolute text-xs bg-white px-1 transform transition-transform",
            {
              "translate-y-[-0.1rem] translate-x-[0.75rem]":
                isFocused || props.value,
              "text-[#2563EB]": isFocused,
              "text-red-500": !!error,
              "opacity-0 translate-y-0 translate-x-0 text-gray-500":
                !isFocused && !props.value && !!!error,
            }
          )}
          style={{ top: "-0.5rem", left: "0.75rem" }}
        >
          {labelText}
        </label>
        <input
          type={type}
          className={cn(
            "flex h-12 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
            {
              "text-red-500 border-red-500": !!error,
              "border-[#2563EB]": isFocused,
            },
            className
          )}
          ref={ref}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...props}
        />
        {!!error && <span className="text-sm text-red-500">{error}</span>}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
