import { IAppointment } from "src/store/booking/bookingSlice";

const Services = ({
  setStep,
  data,
  setFieldValue,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  data: IAppointment;
  setFieldValue: any;
}) => {

  return (
    <div className="flex flex-col justify-between">
      <div>
        <div className="text-center bg-[#EFF6FF] py-2 text-[#64748B] font-medium border-y border-y-[#F1F5F9]">
          <p>Available services for booking</p>
        </div>
        <div className="px-4">
          {data.service_items &&
            data.service_items.map((service, idx) => {
              return (
                <div key={idx}>
                  <div className="flex justify-between items-center py-2">
                    <div>
                      <p className="text-[13px] font-bold text-[#334155]">
                        {service.name}
                      </p>
                      <p className="text-[#64748B] font-semibold text-xs">
                        Service fee: N{service.price}
                      </p>
                    </div>
                    <div>
                      <div
                        className="flex items-center bg-[#2563EB] px-5 text-[#FFFFFF] h-8 rounded-3xl font-[700] cursor-pointer"
                        onClick={() => {
                          setFieldValue("serviceName", service.name)
                          setFieldValue("service_id", service.service_id)
                          setStep(2)}}
                      >
                        Book now
                      </div>
                    </div>
                  </div>
                  <div className="border-y border-y-[#F1F5F9]"></div>
                </div>
              );
            })}
        </div>
        <div className=" bg-[#EFF6FF] py-2 text-[#64748B] font-medium text-xs px-4 border-y border-y-[#F1F5F9]">
          <p>Note from the vendor</p>
        </div>
      </div>
    </div>
  );
};

export default Services;
