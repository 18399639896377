import { createSlice } from "@reduxjs/toolkit";
import { registerCustomer } from "./thunkAction";

export interface IAppointment {
  id: string;
  date_created: string;
  date_updated: string;
  tenant_id: string;
  title: string;
  service_items: {
    name: string;
    price: string;
    quantity: number;
    service_id: string;
  }[];
  available_time_from: string;
  available_time_to: string;
  days_of_week: string[];
  duration_per_session: number;
  duration_per_session_unit: "minutes";
  no_clients: number;
  booking_fee: string;
  booking_fee_currency: string;
  note_for_customers: string;
  status: "active";
  tenant_info: {
    name: string;
    contact: {
      email: string;
      last_name: string;
      first_name: string;
      country_code: string;
      phone_number: string;
    };
  };
}

interface IState {
  loading: "failed" | "loading" | "successful" | "idle";
}
const initialState: IState = {
  loading: "idle",
};
const CustomerSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(registerCustomer.pending, (state) => {
      return { ...state, loading: "loading" };
    });
    builder.addCase(registerCustomer.fulfilled, (state) => {
      return {
        ...state,
        loading: "successful",
      };
    });
    builder.addCase(registerCustomer.rejected, (state) => {
      return { ...state, loading: "failed" };
    });
  },
});

export const CustomerReducer = CustomerSlice.reducer;
