import React, { Fragment, useEffect, useState } from "react";
import Select, { components } from "react-select";
import dayjs from "dayjs";
import { Calendar } from "src/component/ui/calendar";
import { getAvailableSlots } from "src/store/booking/thunkAction";
import { useAppThunkDispatch, useAppSelector } from "src/store/store";
import { Loader } from "lucide-react";

const Details = ({
  touched,
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}: any) => {
  const [timeOptions, setTimeOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [date, setDate] = React.useState<string>();
  const [time, setTime] = React.useState<any>();
  const [month, setMonth] = React.useState(dayjs().toISOString());
  useEffect(() => {
    if (date) {
      setFieldValue("appointment_date", date.slice(0, 10));
    }
  }, [date, setFieldValue]);
  useEffect(() => {
    if (time) {
      setFieldValue("appointment_time_from", time?.value?.split("-")?.[0]);
      setFieldValue("appointment_time_to", time?.value?.split("-")?.[1]);
      setFieldValue("time", time.value || "00:00-00:00");
    }
  }, [time, setFieldValue]);

  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const dispatch = useAppThunkDispatch();
  useEffect(() => {
    const date = dayjs().toISOString();
    dispatch(
      getAvailableSlots({
        tenant_id: values.tenant_id,
        appointment_id: values.appointment_id,
        appointment_date: month < date ? date.slice(0, 10) : month.slice(0, 10),
      })
    );
  }, [dispatch, month, values.tenant_id, values.appointment_id]);
  const { slotsLoading, bookingSlots } = useAppSelector(
    ({ booking }) => booking
  );

  const handleTimeOptions = (date: string) => {
    const availableTimes =
      bookingSlots.slots &&
      bookingSlots.slots.filter((slot) => slot.split("-")?.[0] === date);
    const temp: { value: string; label: string }[] = [];
    availableTimes.forEach((time) => {
      temp.push({
        value: time.split("-").slice(1).join("-"),
        label: time.split("-").slice(1).join("-"),
      });
    });
    setTimeOptions(temp);
  };

  useEffect(() => {
    if (bookingSlots?.slots && bookingSlots.slots.length > 0) {
      const temp: string[] = [];
      bookingSlots.slots.map((slot) => temp.push(slot.split("-")?.[0]));
      setAvailableDates([...new Set(temp)]);
    }
  }, [bookingSlots]);

  const TimeMenu = (props: any) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="flex flex-col">
            {slotsLoading === "loading" ? (
              <span className="p-2">Fetching data...</span>
            ) : (
              <div>{props.children}</div>
            )}
          </div>
        </components.Menu>
      </Fragment>
    );
  };
  const Option = (props: any) => {
    return (
      <Fragment>
        <components.Option {...props}>{props.children}</components.Option>
      </Fragment>
    );
  };

  return (
    <div className="flex flex-col justify-between">
      <div>
        <div className="text-center bg-[#EFF6FF] py-2 text-[#64748B] font-medium border-y border-y-[#F1F5F9]">
          <p>Appointment details</p>
        </div>
        <div className="p-4 grid gap-4">
          <div
            className={`input-box ${
              !errors.serviceName && touched.serviceName && values.serviceName
                ? "filled"
                : errors.serviceName && touched.serviceName
                ? "error"
                : ""
            }`}
          >
            <input
              type="text"
              autoComplete="off"
              id="serviceName"
              name="serviceName"
              value={values.serviceName}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
            {/* <label htmlFor="serviceName" className="label-name">
              <span className="label-text">Service type</span>
            </label> */}
          </div>
          <div className="relative">
            {slotsLoading === "loading" && (
              <div className="absolute grid place-items-center w-full h-full z-40 cursor-not-allowed">
                <Loader />
              </div>
            )}
            <Calendar
              mode="single"
              selected={dayjs(date).toDate()}
              onMonthChange={(month: Date) => {
                console.log(month);
                setMonth(dayjs(month).format("YYYY-MM-DD"));
              }}
              onSelect={(date: Date) => {
                setDate(dayjs(date).format("YYYY-MM-DD"));
                handleTimeOptions(String(dayjs(date).date()));
              }}
              className="rounded-md border shadow w-full"
              disabled={(date: Date) =>
                !availableDates.includes(String(date.getDate())) ||
                slotsLoading === "loading"
              }
              disableNavigation={slotsLoading === "loading"}
              showOutsideDays={false}
              classNames={undefined}
            />
          </div>
          <p>
            Selected Date:{" "}
            <span>{date ? dayjs(date).format("YYYY-MM-DD") : "-"}</span>
          </p>
          <div className="input-box">
            <Select
              value={time}
              name="Select appointment time"
              onChange={(selectedTime) => {
                setFieldValue(
                  "appointment_time_from",
                  selectedTime.value.split("-")[0]
                );
                setFieldValue(
                  "appointment_time_to",
                  selectedTime.value.split("-")[1]
                );
                setFieldValue("time", selectedTime.value || "00:00-00:00");
                setTime(selectedTime);
              }}
              options={timeOptions}
              placeholder="Select appointment time"
              components={{ Menu: TimeMenu, Option }}
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: "48px",
                  fontSize: "16px",
                }),
              }}
            />
            <div>
              {errors?.time && touched?.time && (
                <p className="error">{errors?.time?.label}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
